@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700,900&display=swap);
figure,
h1 {
  margin: 0; }

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.header_header__lQPkg {
  display: flex;
  align-items: center;
  height: 4rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3); }
  .header_header__lQPkg .header_title__3XcAj {
    margin-left: 5%;
    font-size: 2rem;
    font-weight: 700;
    color: #444; }
  .header_header__lQPkg .header_selected__NFi_K {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 5%;
    height: 25px;
    width: 60px;
    border-radius: 15px;
    background-color: #e53935;
    font-weight: 700;
    color: #FAFAFA; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.filter_filter__2EkjK {
  font-weight: 500;
  font-size: 16px;
  border: none;
  background-color: transparent; }

.filter_blue__J9mtX {
  color: #46c7fc; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.filters_filters__1f7tY {
  display: flex;
  padding-bottom: 30px; }
  @media screen and (max-width: 800px) {
    .filters_filters__1f7tY {
      text-align: center; } }
  .filters_filters__1f7tY > * + * {
    margin-left: 15px; }
  .filters_filters__1f7tY .filters_difficulty__PQJa2 {
    color: #777;
    font-weight: 700; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.checkbox_checkbox__1-iFO {
  -webkit-appearance: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 3px solid #fff;
  cursor: pointer; }
  .checkbox_checkbox__1-iFO:checked {
    border-color: #46c7fc;
    background-color: #46c7fc; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.trail_figure__1Q-71 {
  width: 100%; }
  .trail_figure__1Q-71 .trail_selection__1Vsg1 {
    position: relative; }
    .trail_figure__1Q-71 .trail_selection__1Vsg1 .trail_checkbox__sPri8 {
      position: absolute;
      bottom: 20px;
      right: 20px; }
  .trail_figure__1Q-71 .trail_caption__3-Zib {
    text-align: center;
    color: #777;
    font-size: larger;
    font-weight: 700; }

.trail_thumbnail__1Eneu {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  object-fit: cover; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.trails_trails__3eBRE {
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 40px;
  padding-bottom: 50px; }
  @media screen and (max-width: 1200px) {
    .trails_trails__3eBRE {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 800px) {
    .trails_trails__3eBRE {
      grid-template-columns: 1fr; } }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.home_home__1uz1W {
  padding: 0 10%; }
  @media screen and (max-width: 800px) {
    .home_home__1uz1W {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .home_home__1uz1W .home_title__12FEX {
    text-align: center;
    font-weight: 100;
    font-size: 3em;
    color: #777;
    padding: 50px 0; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
.backButton_button__1jupF {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5%;
  left: 5%;
  height: 40px;
  width: 150px;
  border: none;
  border-radius: 5px;
  background-color: #46c7fc;
  color: #FAFAFA;
  font-size: 16px;
  text-decoration: none; }

/* COLORS ---*/
/* FONTS ---*/
/* LAYOUT ---*/
/* HEADINGS */
/* BREAKPOINTS ---*/
a {
  color: #fff; }

.trail_trail__2WgpM {
  position: relative;
  height: calc(100vh - 4rem);
  width: 100%; }
  .trail_trail__2WgpM .trail_img__3wAQ- {
    vertical-align: top;
    width: 100%;
    height: 100%; }
  .trail_trail__2WgpM .trail_caption__1O8zP {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 40px;
    right: 50px;
    color: #FAFAFA;
    font-size: 3em; }
    @media screen and (max-width: 800px) {
      .trail_trail__2WgpM .trail_caption__1O8zP {
        justify-content: center;
        font-size: 16px;
        right: 0; } }
    .trail_trail__2WgpM .trail_caption__1O8zP .trail_checkbox__TK4pd {
      margin-right: 25px; }

