@import "style/_variables.scss";

.figure {
  width: 100%;

  & .selection {
    position: relative;

    & .checkbox {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }
  }

  & .caption {
    text-align: center;
    color: $color-gray;
    font-size: larger;
    font-weight: $font-bold;
  }
}

.thumbnail {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}