@import "style/_variables.scss";

.button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 5%;
  left: 5%;
  height: 40px;
  width: 150px;
  border: none;
  border-radius: 5px;
  background-color: $color-primary;
  color: $color-gray-lightest;
  font-size: $font-size;
  text-decoration: none;
}
