@import "style/_variables.scss";

.filter {
  font-weight: 500;
  font-size: $font-size;
  border: none;
  background-color: transparent;
}

.blue {
  color: $color-primary;
}
